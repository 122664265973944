import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ViewList} from '../list/viewList';
import {DivApiDirective} from '../../../now/divApi';
import * as moment from 'moment';
import {Viewer} from '../../../services/viewer';
import {JobModel} from '../../../models/job.model';
import {ProductModel} from '../../../models/product.model';

@Component({
    selector: 'job-list-component',
    templateUrl: 'jobList.component.html',
    styleUrls: ['jobList.component.scss']
})
export class JobListComponent extends ViewList implements AfterViewInit {

    @ViewChild(DivApiDirective, { static: true }) _divApi: DivApiDirective;

    public sum_grand_total: number;
    public total_items: number;
    public jobs: any[];
    public keyword: string;

    public timeout: any;
    public date: any;
    public tmp_date: string;
    public current_view: string;

    constructor(
        public viewer: Viewer,
    ) {
        //
        super();
        this.jobs = [];
        this.total_items = 0;
        this.current_view = 'MONTHLY';
        this.date = moment(new Date());
        this.tmp_date = this.date.format('MM/YYYY');
        this.sum_grand_total = 0;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.divApi = this._divApi;
        }, 0);
    }

    public onDateChange(e: any): void {
        if (this.divApi && this.divApi.refresh && e && moment.isMoment(e)) {
            this.keyword = '';
            if (this.current_view === 'DAILY') {
                this.divApi.refresh({
                    sent_date: e.format('DD/MM/YYYY')
                }).subscribe(() => {
                    //
                }, error => {
                    //
                }, () => {
                    //
                });
            } else if (this.current_view === 'MONTHLY') {
                this.divApi.refresh({
                    sent_date: e.format('MM/YYYY')
                }).subscribe(() => {
                    //
                }, error => {
                    //
                }, () => {
                    //
                });
            } else if (this.current_view === 'YEARLY') {
                this.divApi.refresh({
                    sent_date: e.format('YYYY')
                }).subscribe(() => {
                    //
                }, error => {
                    //
                }, () => {
                    //
                });
            }
        } else {
            //
        }
    }

    public viewProductDetail(product: ProductModel): void {
        this.viewer.product(product);
    }

    public viewManufacture(job: JobModel): void {
        this.viewer.manufacture(job, true, true)
            .then(() => {
                //
            });
    }

    public onKeywordInput(e: any): void {
        this.clear_timeout();
        this.timeout = setTimeout(() => {
            this.divApi.refresh({
                limit: this.item_per_page,
                page: this.current_page,
                find: this.keyword
            }).subscribe(() => {
                //
            }, error => {
                //
            }, () => {
                //
            });
        }, 300);
    }

    public clear_timeout(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    public onSuccess(response: any): void {
        this.total_items = (response && response.meta && response.meta.total_items) ? response.meta.total_items : response.total_items;
        if (response && response.data && Array.isArray(response.data)) {
            this.clear_jobs();
            this.sum_grand_total = response.sum_grand_total;
            for (let i = 0; i < response.data.length; i++) {
                let dat: any;
                dat = response.data[i];
                if (dat) {
                    let job: any;
                    job = new JobModel();
                    job.clone(dat);
                    this.jobs.push(job);
                }
            }
        }
    }

    public onError(e: any): void {
        //
    }

    private clear_jobs(): void {
        if (this.jobs) {
            this.jobs.splice(0, this.jobs.length);
        } else {
            this.jobs = [];
        }
    }

    public createQuotation(): void {
        //
    }

}
